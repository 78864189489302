@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GT-America-Standard-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT-America-Standard-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GT-America-Standard-Regular-Italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT-America-Standard-Regular-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Regular-Italic.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Regular-Italic.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GT-America-Standard-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT-America-Standard-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GT-America-Standard-Bold-Italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT-America-Standard-Bold-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Bold-Italic.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT-America-Standard-Bold-Italic.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: italic;
}

:root{
    --margin:30px;
    --navHeight:80px;
    --gutter:60px;
    --red:#F4364C;
    --green:#6BCABA;
    --spacer: 80px;
    --dark: #333333;
}

html{
    -webkit-font-smoothing: antialiased;
    // font-family: 'GT America', sans-serif;
    
}
body{
    margin:0px;
    font-size:24px;
    font-family: 'William Text Regular', serif;
    line-height:1.25;
    color:var(--dark);
}
.ss{
    font-family:'GT America', sans-serif;
}
ul{
    list-style-type:none;
    margin:0px;
    padding:0px;
}
a{
    color:black;
    text-decoration:none;
}
button, input[type="submit"]{
    border:0px;
    padding:0px;
    border-radius:0px;
    background:transparent;
    font-size:1rem;
    cursor:pointer;
}
h1, .h1, .h1 a{
    font-size:60px;
    text-transform:none;
    font-weight:normal;
    line-height:1;
    font-family:'GT America', sans-serif;
    // margin-bottom:36px;
    margin-bottom:0px;
}
h1 + a{
    margin-top: 1em;
}
h2, h4{
    font-weight:normal;
    font-family:'GT America', sans-serif;
}
h2, .h2{
    font-size:28px;
    line-height:34px;
    font-family:'GT America', sans-serif;
}
h2 a{
    border-bottom:3px solid var(--green);
    transition: border 300ms;
}
h2 a:hover{
    border-bottom:3px solid var(--red);
}
h3, .h3{
    font-size:36px;
    line-height:42px;
    font-family:'GT America', sans-serif;
    font-weight:normal;
    margin-top:0px;
}
h4{
    text-transform: uppercase;
    font-size:16px;
    line-height:20px;
    letter-spacing: 0.5px;
}
h5{
    font-size:21px;
    line-height:26px;
    font-family:'GT America', sans-serif;
    margin-top:10px;
    font-weight:normal;
}
h6{
    text-transform:uppercase;
    letter-spacing:0.5px;
    font-size:16px;
    font-family:'GT America', sans-serif;
    font-weight:normal;
    margin:0px;
    margin-bottom: 10px;
}
p{
    margin-top:0px;
}
p.sm{
    font-size:16px;
    line-height:21px;
}
p a, p strong{
    font-weight:bold;
    font-family:'GT America', sans-serif;
}
p a{
    border-bottom:1px solid black;
}
img{
    width:100%;
}
blockquote{
    color:var(--red);
    margin-inline-start: 0px;
    margin-block-start:0px;
    font-family:'GT America', sans-serif;
}
blockquote p {
  text-indent: -.4125em;
}
.hr{
    width:100%;
    height:1px;
    border-bottom:1px solid #969696;
    height:100px;
}
button:focus, input[type="submit"]:focus{outline:none;}
#hamburger{display:none;}
nav{
    position:sticky;
    padding:20px var(--margin);
    width:calc(100% - var(--margin)*2);
    // top:0px;
    top:-0.5px;
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #969696;
    background:white;
    z-index: 2;
    > ul{
        display:flex;
        flex:8;
        li {
            margin-right:50px;
        }
        button, a{
            text-transform:uppercase;
            font-weight:bold;
            text-decoration: none;
            letter-spacing: 0em;
            color: var(--dark);
            font-size:28px;
            font-family:'GT America', sans-serif;
        }
        a.newsletter-link{
            text-transform:unset;
        }
        a.newsletter-link:hover{
            color:var(--green);
        }
    }
    #logo{
        height: 42px;
        transition: height 400ms;
        flex: 4;
        margin-right: var(--gutter);
        svg{
            height:100%;
            width:50px;
            transition: width 400ms;
            path{
                transition:fill 400ms;
            }
        }
    }
    #logo svg:hover{
         path{fill:var(--red)}
    }
    .subnav{
        position:fixed;
        width:100vw;
        transform:translateY(-200%);
        transition:transform 400ms;
        z-index:-1;
        left:0px;
        top:77px;
        box-shadow: 0px 3px 6px #00000080;
        display:flex;
        background:white;
        svg{width:36px;height:36px;}
        li{
            margin-left:0px;
            margin-bottom:36px;
        }
        a{transition: color 200ms}
        li:hover a{color:var(--green)}
        li a{display:inline-block;padding-right: 61px;}
        li:hover a:not(.external):after{
            content:'→';
            display:inline-block;
            animation:fadeRight 100ms both ease-in;
            position:relative;
            white-space:nowrap;
            margin-right:-61px;
            // color:var(--green);
        }
        li:hover svg path{
            fill:var(--green);
        }
        @keyframes fadeRight{
            0%{left:0px;opacity:0;}
            100%{left:10px;opacity:1;}
        }
        ul{
            flex: 9;
            padding:var(--margin);
            border-right:1px solid #969696;
        }
        .right{
            flex:3;
            height:100%;
            padding:var(--margin);
            > * {
                margin-bottom:40px;
            }
            span{
                font-family:'GT America', sans-serif;
                font-weight:bold;
            }
            .social img{
                margin-right:10px;
            }
        }
        .donateBtn{
            border-radius:50%;
            background:var(--red);
            font-size:24px;
            text-transform:none;
            height:150px;
            width:150px;
            color:white;
            text-align:center;
            display:flex;
            flex-direction: column;
            justify-content: center;
            transition:transform 300ms;
        }
        .donateBtn:hover{
            transform:scale(1.03);
        }
        .h1{
            max-width:740px;
        }
    }
    button.active + .subnav{transform:translateY(0%);}
    .background{
        width:100%;
        height:100%;
        background:white;
        position:absolute;
        z-index:-1;
        top:0px;
        left:0px;
        border-bottom:1px solid #969696;
    }
}
nav.lg{
    #logo{
        height:136px;
        svg{
            width:155px;
            #logoCover1, #logoCover2{
                fill: transparent;
            }
        }
    }    
}
nav.subnav-open > ul > li {
    > button, > a{
        color:#e5e5e5;
    }
}
nav.subnav-open .active{
    color:var(--dark);
}
#homepage nav ul{
    justify-content:flex-end;
    li:last-child{margin-right:0px;}
}
#page-container {
  position: relative;
  z-index:1;
  background: white;
//   padding-bottom:159px; //footer height
}
footer{
    padding:var(--spacer) var(--margin) var(--margin);
    width:calc(100% - var(--margin)*2);
    display:flex;
    font-family:'GT America', sans-serif;
    font-size:18px;
    line-height:24px;
    // position: absolute;
    bottom: 0;
    // border-top:1px solid #969696;
    > * {
        flex:1;
    }
    > *:last-child{
        text-align:right;
        display:flex;
        flex-direction:column;
        justify-content: flex-end;;
    }
    a{
        text-decoration: none;
        margin-left:10px;
    }
    a.btn{margin-left:15px;}
}


#screensaver{
    opacity:0;
    width:calc(100vw + 50px);
    height:calc(100vh + 90px);
    position:fixed;
    background:rgba(255,255,255,0.5);
    top:0px;
    left:-21px;
    z-index:10;
    transition:opacity 400ms;
    overflow:hidden;
    display: flex;
    flex-wrap: wrap;
    pointer-events:none;
    user-select:none;
    img{
        width:50px;
        height:42px;
        position:fixed;
        filter: none !important;
        margin-right:10px;
        opacity:0;
    pointer-events:none;
    user-select:none;
    }
}
body.screensaver{
    img, a {
        filter: grayscale(100%);
    }
    #screensaver{opacity:1}
}

.easter-egg{
    height:33vh;
    display:none;
    // background:var(--red);
}
body.view-easter-egg {
    .easter-egg{
        display:block;
    }
    #page-container {
        border-bottom:1px solid var(--green);
    }
}

:root{
  --mark-width: 70px;
  --mark-height: calc(var(--mark-width) * 0.85);
}

.pattern-container{
  width:100vw;
  height:100vh;
  position:fixed;
  top:0px;
  left:0px;
  overflow:hidden;
}

#pattern1, #pattern2, #pattern3{
  width:calc(100vw + 200px);
  height:calc(100vh + 200px);
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  display:none;
}

#pattern1 .mark-container{
  display:inline-block;
  padding:20px;
}
#pattern1 .mark{
  background:rgb(244, 54, 76);
  clip-path: polygon(41% 0%, 59% 0%, 100% 100%, 0% 100%);
  height:var(--mark-height);
  width:var(--mark-width);
}

#pattern1 .mark-container.animate .mark{
  animation: pattern1 ease-in-out 1s;
}

#pattern1 .mark-container:nth-child(odd) .mark{
  background:#333333;
}

@keyframes pattern1 {
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}

#pattern3{
    background:var(--red);
}

#pattern2,#pattern3{
    img{
    width:100px;
    display:inline-block;
    margin:0px 9px -4px;
    clip-path: polygon(42% 0%, 58% 0%, 99% 99%, 1% 99%);
    background:white;
    }

    .row:nth-child(odd){
    margin-left:-58px;
    }
}

#pattern2 img.animate {
  animation: pattern2 linear 900ms;
  animation-iteration-count:2;
  mix-blend-mode:MULTIPLY;
} 

#pattern3 img.animate {
  animation: pattern3 linear 900ms;
} 

@keyframes pattern3 {
  0%{transform:scale(1.1) rotate(1deg);background:white;}
  100%{transform:scale(1)  rotate(0deg);background:white;}
}  

@keyframes pattern2 {
  0%{
    transform: rotateY(0deg);
  }
  24.99%{
    transform:scale(1)  rotateY(90deg);
    filter:none;
  }
  25%{
    transform:scale(1.2)  rotateY(-90deg);
    filter:invert(1) brightness(1.75);
  }
  50%{
    filter:invert(1) brightness(1.75);
    transform:scale(1.2)  rotateY(0deg);
  }
  74.99%{
    transform:scale(1.2)  rotateY(90deg);
    filter:invert(1) brightness(1.75);
  }
  75%{
    transform:scale(1)  rotateY(-90deg);
    filter:none;
  }
  100%{transform:scale(1) rotateY(0deg)}
} 


a.btn{
    background:var(--green);
    padding:3px 10px;
    font-family:'GT America', sans-serif;
    font-size:18px;
}
a.btn-secondary{
    font-weight:bold;
    // color:var(--red);
    font-family:'GT America', sans-serif;
    background:var(--green);
    color:black;
    padding:5px 15px;
    margin-bottom:7px;
    display:inline-block;
    transition: color 300ms, background 300ms;
}
a.btn-secondary:hover{
    background:#d1d1d1;
    // color:var(--green);
}
a.btn-primary{
    font-weight:bold;
    font-family:'GT America', sans-serif;
    color:var(--red);
    border:5px solid var(--red);
    padding:20px 30px;
    transition: background 200ms, color 200ms;
}
a.btn-primary:hover{
    background:var(--red);
    color:white;
}
div.text-block + a.btn-primary {
    margin-top:50px;
}

p.lg{
    font-size:42px;
    margin-top:0px;
}
.image-container{
    position: relative;
    width: 100%;
    border:1px solid var(--green);
    display:inline-block;
    img{
        display:block;
        width:100%;
        height:auto;
        border:0px;
        opacity:0;
        transition:opacity 400ms;
        transition-delay:300ms;
    }
}
.image-container.fade-in {
    border:0px;
    // animation:removeDisplay 0s 1s forwards;
    img{
        opacity:1;
    }
}
// @keyframes removeDisplay{
//     0%{display:inline-block}
//     100%{display:inline !important}
// }
.home.main {
    display:flex;
    > div {
        width:calc(50vw - 0.5px);
        border-right:1px solid #969696;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        > div{
            padding:var(--margin);
            border-bottom:1px solid #969696;
        }
    }
    .image-container.fade-in img{
        border:0.5px solid #969696 !important;
    }
    :last-child{
        border-right:0px solid #969696;
    }
    
    #custom{
        display:flex;
        flex-direction:column;
        justify-content: center;
        height:100%;
        background-size:cover;
        background-position:center;
        .module{
            border: 5px solid var(--red);
            padding:var(--margin);
            background:white;
            h2{margin:0px;}
        }
    }
}
.social img{
    width:26px;
    position: relative;
    top: 8px;
}

.main-two-up{
    display:flex;
    padding:var(--margin);
    img{
        width:100%;
    }
    > div:first-child{
        flex:4;
        margin-right:calc(var(--gutter)/2);
        h2, h3, .h3 {
            position:sticky;
            top:100px;
            margin-top:0px;
        }
        > *:not(.lefthand-link){
            max-width:360px;
        }
        >.podcast {
            position: sticky; 
            margin-top: 0; 
            top: 100px;
            .listen {
                color: var(--red);
                font-family: 'GT America', sans-serif;
                font-weight: bold;
                font-size: 24px;
                line-height: 30px;
                display: inline-block;
                max-width: 300px;
            }
        }
    }
    > div.podcast{
        h2, h3, .h3 {
            position:static;
        }
        .listen {
            color: var(--red);
            font-family: 'GT America', sans-serif;
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            display: inline-block;
            max-width: 300px;
        }            
    }

    .podcast-related-reading {
        h5 {
            font-size: 30px;
        }
        .description {
            font-family: 'William Text Regular', serif;
        }
    }
    
    .pcast-download {
        color: var(--red);
        font-family: 'GT America', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        display: inline-block;
        max-width: 300px;
    }
    > div:nth-child(2){
        flex:8;
        margin-left:calc(var(--gutter)/2);
        > div{
            // margin-bottom:var(--spacer);
        }
    }
    h1{
        margin-top:0px;
        // max-width:400px;
    }
    .text-with-download{
        p{
            font-family:'GT America', sans-serif;
            font-size:24px;
            font-weight:bold;
            line-height:30px;
            margin:0px;
        }
        a{
            color:var(--red);
            font-size:18px;
            line-height:24px;
            font-family:'GT America', sans-serif;
        }
    }
    .text-block{
        ul {
            li{
                margin-bottom:15px;
            }
            li:before{
                content:'';
                width:12px;
                height:12px;
                display:inline-block;
                background-color:var(--red);
                border-radius:50%;
                margin-right:20px;
            }
        }
        h6{
            margin-bottom:2em;
        }
        ul, p{
            margin-bottom:2em;
        }
    }
    .img-block img{
        max-height: calc(66vw - var(--margin)*2 - var(--gutter));
        // max-height: calc(50vw - var(--margin)*2 - var(--gutter));
        object-fit: cover;
    }
    .media-gallery-block, .other-film{
        display:flex;
        flex-wrap:wrap;
        > * {
            width:calc(50% - 30px);
        }
        > *:nth-child(odd){
            margin-right:calc(var(--gutter)/2);
        }
        > *:nth-child(even){
            margin-left:calc(var(--gutter)/2);
        }
        img{
            width:100%;
            height: 20vw;
            object-fit:cover;
        }
        .img-container{position: relative;display:inline-block !important;width:100%}
        .play{
            display:block;
            position:absolute;
            width: 0;
            height: 0;
            border-top: 40px solid transparent;
            border-left: 80px solid rgba(255,255,255,0.8);
            border-bottom: 40px solid transparent;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        a:hover .play{border-left: 80px solid rgba(255,255,255,1);}
        a h5 svg{
            display:none;
            width:16px;
            height:16px;
        }
        a:hover h5 svg{
            display:inline-block;
        }
        h5{
            margin-bottom:var(--spacer);
        }
    }
    .spacer{
        margin-bottom:var(--spacer);
    }
    .timeline{
        ul li {display:flex;margin-bottom:20px;}
        b{
            font-family:'GT America', sans-serif;
        }
        h3{margin:0px;flex:1;}
        p{margin:0px;flex:5;margin-top: 10px;}
    }
    .portrait-gallery-block{
        display:flex;
        flex-wrap:wrap;
        > div{
            width:calc(33.333% - var(--gutter)*2/3);
            margin-right:var(--gutter);
            margin-bottom:var(--gutter);
        }
        > div:nth-child(3n+3){
            margin-right:0px;
        }
        p{    
            font-size:16px;
            line-height:21px;
        }
        img, .img{
            width:100%;
            padding-top: 100%; /* 1:1 Aspect Ratio */
            position: relative;
            height: 0px;
            background-position:center;
            background-size: cover;
        }
    }
    .portrait-gallery-block-small{
        display:flex;
        flex-wrap:wrap;
        // justify-content: space-between;
        > div{
            width:calc(25% - var(--gutter)*3/4);
            font-size:18px;
            line-height:24px;
            font-family:'GT America', sans-serif;
            margin-bottom: 50px;
            margin-right:var(--gutter);
            img, .img{
                margin-bottom:10px;
                height: 0px;
                padding-top:100%;
                // background-position:center;
                background-size: cover;
                background-position:center 10%;
            }
        }
        > div:nth-child(4n+4){
            margin-right:0px;
        }
        p {
            font-family: 'William Text Regular', serif;
        }
    }
    .portrait-gallery-block-small::after {
        content: "";
        flex: auto;
    }
    .portrait-gallery-block-large{
        > div {
            margin-bottom:var(--gutter);
            display:flex;
            align-items: flex-start;
            > div:first-child{
                flex:1;
                margin-right:var(--gutter);
            }
            > div:last-child{
                flex:2;
            }
        }
        h2, .h2{
            margin-top:0px;
        }
        p{
            font-size:16px;
            line-height:21px;
        }
    }
    .logos-block{
        display:flex;
        flex-wrap:wrap;
        // justify-content: space-between;
        > div{
            width:150px;
            margin-right:var(--gutter);
            display:flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .sponsors-amounts > li{
        display:flex;
        margin-bottom:20px;
        :first-child{flex:1}
        :nth-child(2){flex:3}
    }
}

//SLIDESHOW
// * {box-sizing: border-box}
// body {font-family: Verdana, sans-serif; margin:0}
.slide {display: none}
.slide img {vertical-align: middle;}

/* Slideshow container */
.slideshow {
//   max-width: 1000px;
  position: relative;
//   margin: auto;
  figcaption{margin-top:15px;}
  figcaption span{
      display:inline-block;
      margin-right:10px;
  }
  svg{
      width:30px;
      cursor:pointer;
      path{fill:var(--red);}
  }
  .arrows{
      position:absolute;
      right:0px;
      top:calc(100% - 30px);
  }
}

.dot.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

.lefthand-link{
    color:var(--red);
    font-family:'GT America', sans-serif;
    font-weight:bold;
    font-size:24px;
    line-height:30px;
    display: inline-block;
    max-width:300px;
}

figcaption, .smallest{
    font-family:'GT America', sans-serif;
    font-size:16px;
    line-height:24px;
}

.prefooter{
    border-top:1px solid #969696;
    border-bottom:1px solid #969696;
    padding:var(--margin);
    padding-bottom:var(--spacer);
    margin-top:120px;
    li a span{
        color:var(--green);
        font-weight:bold;
        position:inline-block;
        margin-left:5px;
    }
    li a:hover{
        color:var(--green);
    }
    li a:hover span{
        margin-left:10px;
    }
}

.support-page{
    background:#F4364C12;
}

.donation-block-inner.pink{background:#F4364C12;}
.donation-block-inner, .newsletter-block-inner{
    background:white;
    padding:56px;
    max-width:600px;
    margin-bottom:270px;
    h2{
        margin-top:0px;
        position:static;
        margin-bottom:30px;
        color:var(--dark);
    }
    > div {
        // margin-bottom:100px;
    }
    .donation-buttons, .sponsorship-buttons {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        > label, input[type="number"]{
            text-align:center;
            border:5px solid var(--red);
            color:var(--red);
            padding:20px;
            width:calc(50% - 70px);
            font-size:36px;
            font-family:'GT America', sans-serif;
            margin-bottom:35px;
            background:white;
            position:static;
            cursor:pointer;
            input[type="radio"]{
                position:fixed;
                visibility:hidden;
                top:-100px;
                left:-100px;
            }
        }
        .btn-other::placeholder{
            color:var(--red);
            font-size:28px;
            transition:color 300ms;
        }
        .btn-other:focus::placeholder{
            color:#F4364C12;
            // font-size:28px;
        }
    }
    .donation-buttons.level-selected, .sponsorship-buttons.level-selected{
        > label:not(.selected),input[type="number"]:not(.selected){
            border:5px solid white;
            color: #969696;
        }
        .btn-other:not(.selected)::placeholder{
            color:#969696;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    .donation-buttons.level-selected > label:not(.selected),
    .donation-buttons.level-selected > input[type="number"]:not(.selected){
        border-color:#c3c3c3;
        color:#c3c3c3;
    }
    .sponsorship-buttons:not(.donation-buttons){
        > label{
            width:calc(100% - 70px);
            display:flex;
            justify-content: space-between;
            visibility:visible;
            position:static;
            span:first-child{
                font-weight:normal;
                font-family: 'William Text Regular', serif;
                font-size: 24px;
                line-height:44px;
            }
        }
    }
    .payment-buttons{
        margin-bottom:60px;
    }
    .payment-buttons button{
        margin-right:60px;
        padding-bottom:7px;
    }
    .payment-buttons button.active{
        color:var(--red);
        border-bottom:5px solid var(--red);
        background:transparent;
    }
    
    label{
        position:fixed;
        visibility:none;
        left:-100px;
        top:-100px;
    }
    input[type="text"], input[type="email"]{
        font-size:24px;
        color:var(--dark);
        border:0px;
        border-bottom:5px solid var(--red);
        padding:7px 0px;
        width:100%;
        margin-bottom:30px;
        background:transparent;
        font-family: 'GT America', sans-serif;
    }

    input[type="text"]:focus, input[type="email"]:focus{
        outline:none;
    }
    input[type="text"]::placeholder, input[type="email"]::placeholder{
        color:var(--dark);
        font-family:'GT America', sans-serif;
    }
    button#donate-form--donate-btn, input[type="submit"]{
        background:var(--red);
        color:white;
        padding:20px 30px;
        text-align:center;
        margin: auto;
        display: block;
        font-size: 28px;
        line-height: 34px;
        font-weight:bold;
        margin-top:100px;
    }
}
.newsletter-block-inner{
    input[type="text"], input[type="email"]{
        border-bottom:5px solid var(--green);
    }
    #mc-embedded-subscribe{
        background:var(--green);
        color:var(--dark);
    }
}

.newsletter-archive{
    a{
        margin-bottom:40px;
        display:block;
    }
    h3{
        border-bottom:2px solid var(--green);
        padding-bottom:5px;
        display:inline-block;
        margin-bottom:10px;
        transition: color 200ms;
    }
    a:hover h3{
        color:var(--green);
        // border-bottom:2px solid var(--dark);
    }
}
.event-block{
    > div{
        display:flex;
    }
    .pretty-load{
        width: 300px;
        height: 300px;
        margin-right:var(--gutter);
    }
    img{
        width: 300px;
        height: 300px;
        object-fit: cover;
    }
    .time-location{
        font-family:'GT America', sans-serif;
        font-size:18px;
        line-height:24px;
    }
}

#bbw-logo{
    width:265px;
}
#bbw-logo-sm{
    display:none;
    width: auto;
    height: 50px;
    position: fixed;
    top: 15px;
    left: 100px;
    z-index: 2;
}

.bbw-image-grid{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    img{
        width:33.333%;
        height:calc((100vw - var(--margin)*2 - var(--gutter))*0.6667/3);
        object-fit: cover;
        object-position:center;
        flex:1;
    }
}
.bbw-image-grid + figcaption{
    margin-top: 10px;
    span:after{
        content: ', ';
        display:inline-block;
        margin-right:0.4em;
    }
    span.last:after{
        content: '';
    }
}
.vertical-flex{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    > * {
        display:block;
    }
}
.vertical-flex-center{
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.stats{
    font-size:18px;
    font-weight:bold;
    line-height:24px;
    > * {
        margin-bottom:50px;
        width:300px;
    }
}
.stat{
    color:var(--red);
    font-weight:normal;
    font-size:80px;
    line-height:1;
}
.nav-bbw{
    width:calc(100% - var(--margin)*2);
    padding:var(--margin);
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #969696;
    > div {
        display:flex;
        flex-direction:column;
        justify-content: flex-end;
        text-align:right;
    }
}
.nav-bbw.small{
    padding:calc(var(--margin)/3) var(--margin);
    #bbw-logo{
        width: auto;
        height: 50px;
        position: fixed;
        top: 15px;
        left: 100px;
        z-index: 2;
    }
}
.bbw-filters{
    padding:var(--margin);
    font-family:'GT America', sans-serif;
    font-size:18px;
    line-height:24px;
    color: var(--dark);
    li{
        display:inline-block;
        margin-right:50px;
    }
    button{
        font-family:'GT America', sans-serif;
        font-size:18px;
        line-height:24px;
        color: var(--dark);
    }
    button.active{
        border-bottom:5px solid var(--dark);
        font-weight:bold;
    }
}

.bbw-projects{
    display:flex;
    flex-wrap:wrap;
    // justify-content: space-between;
    // padding:var(--margin);
    // margin:calc(var(--gutter)/-2);
    > div{
        width:calc((100vw - var(--margin)*2)/4 - var(--gutter)*3/4 - 5px);
        margin:calc(var(--gutter)/2);
    }
    label{
        display:none;
    }
    .historical{
        background:#ffd6db;
        padding:15px;
        width:calc((100vw - var(--margin)*2)/4 - var(--gutter)*3/4 - 5px - 30px);
        position:relative;
        top:-15px;
        label{
            display:block;
            font-size: 14px;
            text-transform:uppercase;
            font-family: 'GT America', sans-serif;
            color:var(--red);
            font-weight:bold;
        }
    }
    img{
        height:calc((100vw - var(--margin)*2)/4 - var(--gutter)*3/4 - 5px);
        width:100%;
        object-fit: cover;
    }
    h2{
        font-size:24px;
        font-family: 'William Text Regular', serif;
        line-height:30px;
        margin-bottom:0px;
    }
    p{
        font-size:18px;
        line-height:24px;
        font-family:'GT America', sans-serif;
    }
}

.bbw-project-page{
    h1{
        font-family: 'William Text Regular', serif;
        font-size: 30px;
        line-height: 40px;
    }
}

// Podcasts

.visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding:0 !important;
    border:0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.podcast-coming-soon {
    color: #f4364c;
    font-family: 'GT America', sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: bold;        
}

.podcast-episode-number {
    font-family: 'GT America', sans-serif;    
    font-size: 60%; 
    font-weight: bold;
    text-transform: uppercase; 
}

.podcast-thumbnail {
    margin-bottom: 1em;
}

/* Podcast Player */

@media (max-width: 1200px) {    
    #sidebar-player {
        display: none;
    }
    #fullsize-player {
        display: block;
    }
}

@media (min-width: 1201px) {    
    #sidebar-player {
        display: block;
    }
    #fullsize-player {
        display: none;
    }
}


.pcast-player {
  
  .pcast-player-controls {
    box-sizing: border-box;
    background: var(--red);
    color: #fff;
    font-size: 14px;
    height: 62px;
    overflow: hidden;
    padding: 5px;
  }  
  
  button {
    background: var(--red);
    color: #fff;
    border: 0;
    border-radius: 2px;
    padding: 5px;
    line-height: 22px;
    -webkit-appreance: none;
    min-width: 32px;
    
    span {
      @extend .visually-hidden;
    }
  }
  
  progress {
    height: 13px;
    width: calc(100% - 281px);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #aaa;
    border-radius: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }
  
  progress[value]::-webkit-progress-value  {
    background: #fff; 
  }
  
.pcast-player-title {
    font-family: 'GT America', sans-serif;
    font-weight: bold;
}

  .pcast-time {
    display: inline-block;
    font-size: 13px;
    padding: 0 5px;
    text-align: center;
    width: 4em;
  }
  
  .pcast-speed {
    width: 2.5em;
  }
  
  .pcast-download {
    font-size: 12px;
    margin-top: 0.5em;
    display: inline-block;
    color: black;
    &:hover,
    &:focus {
      color: #666;
    }
  }
}

.searchbar{
    width:100%;
    border:0px;
    border-bottom:5px solid var(--green);
    font-size:36px;
    font-family:'GT America', sans-serif;
    margin-bottom:118px;
}
.searchbar:after{
    content:'→';
    display:block;
    position:absolute;
    right:0px;
    color:var(--green);
}
.searchbar:focus{
    // border-bottom:5px solid black;
    // color:var(--green);
    outline:none;
    background:#6BCABA26;
}
#search-results{
    > a{
        display:flex;
        align-items: flex-start; 
        justify-content: flex-start;
        margin-bottom:80px;
        // flex-wrap:wrap;
        div:last-child{
            max-width:930px;
        }
    }
    .thumbnail{
        img{
            width:180px;
            height:180px;
            opacity:1;
            object-fit:cover;
        }
        margin-right:var(--gutter);
        width:auto;
        height:auto;
        border:1px solid #969696;
    }
}

.lightgreen{
    background:#6BCABA26;
}


@media screen and (min-width: 1800px){
    body {
        font-size: 30px;
    }
    .logos-block > div{
        width:200px;
    }
}

@media screen and (min-width: 1600px){
    .main-two-up .img-block img{
        max-height:80vh;
    }
}

@media screen and (max-width:1455px){
    .main-two-up .portrait-gallery-block>div{
        width: calc(50% - var(--gutter)/2);
    }
    .main-two-up .portrait-gallery-block> div:nth-child(3n+3){
        margin-right:var(--gutter);
    }
    .main-two-up .portrait-gallery-block> div:nth-child(2n+2){
        margin-right:0px;
    }
}



@media screen and (max-width: 1250px){
    .main-two-up>div:first-child{flex:3}
    .main-two-up>div:nth-child(2){flex:9}
}

@media screen and (max-width: 1050px){
    // footer>*{
    //     display:flex;
    //     flex-direction:column;
    //     justify-content:flex-end;
    // }

    p.lg{font-size:34px;}

    footer>*:last-child{
        text-align:left;
        // justify-content:flex-start;
        a.btn{
            display:inline-block;
        }
    }
    footer{flex-wrap:wrap;}
    footer>*{width:33.333%;flex:none;margin-bottom:1em;}
    footer>*:first-child, footer>*:last-child{width:100%}
    nav .subnav svg{
        width:20px;
        height:20px;
    }

    // hamburger
    #hamburger{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        width:40px;
        height:30px;
        margin-top:auto;
        margin-top: 10px;
        margin-bottom:auto;
        position: absolute;
        top: var(--margin);
        right: var(--margin);
        cursor:pointer;
        span{
            display:block;
            // width:100%;
            width:40px;
            height:4px;
            background:black;
        }
    }
    nav{display: block;}
    nav ul{display:none;margin-top:1em}
    nav.expanded {
        ul{display:block;}
        ul li{
            margin-left:0px;
        }
    }
    nav.subnav-open>ul>li>button, nav.subnav-open>ul>li>a{
        color:black;
    }
    nav .subnav{
        z-index:1;
        flex-wrap:wrap;
        overflow: scroll;
        box-shadow:none;
        height: calc(100vh - 77px);
        transform: translateX(200%);
    }
    nav button.active+.subnav{
        transform: translateX(0%);
    }
    nav .subnav li{
        margin-bottom:15px;
    }
    nav .subnav .h1 a{
        font-size:30px;
    }
    nav>ul li{
        margin-right:0px;
    }
    nav .subnav li:hover a:not(.external):after{
        display:none;
    }
    nav .subnav li a{
        display:block;
    }

    .bbw-projects>div{
        width: calc((100vw - var(--margin)*2)/3 - var(--gutter)*2/3 - 5px)
    }
    .bbw-projects img{
        height: calc((100vw - var(--margin)*2)/3 - var(--gutter)*2/3 - 5px)
    }
    .bbw-projects .historical{
        width: calc((100vw - var(--margin)*2)/3 - var(--gutter)*2/3 - 5px - 30px);
    }
}

@media screen and (max-width: 950px){
    .main-two-up{
        display:block;
        h1{
            margin-bottom: 0.6em;
        }
    }
    .main-two-up>div:nth-child(2){
        margin-left:0px;
    }
    .lefthand-link{
        margin-bottom:1em;
    }
    .text-with-download{
        margin-bottom:var(--gutter);
    }
    .donation-block-inner, .newsletter-block-inner {    
        padding:20px;
        width:  calc(100% - 40px);
    }
    
}

@media screen and (max-width: 800px){
    :root{
    --gutter:40px;
    }
    .main-two-up .portrait-gallery-block-small>div{
        width: calc(33.3333% - var(--gutter)*2/3);
    }
    .main-two-up .portrait-gallery-block-small>div:nth-child(4n+4){
        margin-right:var(--gutter);
    }
    .main-two-up .portrait-gallery-block-small>div:nth-child(3n+3){
        margin-right:0px;
    }
    body{
        font-size:20px;
    }
    .main-two-up .timeline ul li{
        display:block;
    }
    .main-two-up .media-gallery-block>*, .main-two-up .other-film>*, .main-two-up .media-gallery-block>*:nth-child(even){
        width:100%;
        margin-left:0px;
        margin-right:0px;
    }
    .home.main{
        flex-wrap:wrap;
    }
    .home.main>div{
        width: 100vw;
        border-right: 0px solid transparent;
    }
    .home.main>div:first-child>div:first-child{
        padding:var(--gutter) var(--margin)
    }
    .main-two-up .portrait-gallery-block-large>div{
        display:block;
        .image-container{
            width:50%;
            margin-bottom:1em;
        }
    }
    .event-block .pretty-load{
        display:block !important;
    }
    .event-block>div{
        display:block;
    }
    .event-block .pretty-load{
        margin-bottom:30px;
    }
    footer>*{width:50%;flex:none;margin-bottom:1em;margin-right:1em}
    .bbw-projects>div{
        width: calc((100vw - var(--margin)*2)/2 - var(--gutter)*1/2 - 5px)
    }
    .bbw-projects img{
        height: calc((100vw - var(--margin)*2)/2 - var(--gutter)*1/2 - 5px)
    }
    .bbw-projects .historical{
        width: calc((100vw - var(--margin)*2)/2 - var(--gutter)*1/2 - 5px - 30px);
    }
}

@media screen and (max-width:700px){
    .donation-block-inner .sponsorship-buttons>div, .newsletter-block-inner .sponsorship-buttons>div{
        display:block;
        text-align: left;
        padding: 10px 15px;
        span{
            display:block;
        }
    }
}

@media screen and (max-width:600px){
    .main-two-up .portrait-gallery-block>div{
        width: 100%;
        margin-right:0px !important;
    }
    .main-two-up .portrait-gallery-block-small>div{
        width: calc(50% - var(--gutter)/2);
    }
    .main-two-up .portrait-gallery-block-small>div:nth-child(2n+2){
        margin-right:0px;
    }
    .main-two-up .portrait-gallery-block-small>div:nth-child(odd){
        margin-right:var(--gutter);
    }
    #bbw-logo{
        width:150px;
        height:150px;
        margin-bottom: 20px;
    }
    .bbw-image-grid img{
        width:50%;
        height: calc((100vw - var(--margin))/2 - 1px);
        object-fit:cover;
    }
    .nav-bbw{
        display:block;
    }
    .nav-bbw>div{
        display:block;
        text-align:left;
        a{
            display:block;
            margin-bottom:0px;
        }
    }
    .bbw-projects>div{
        margin-right:0px;
    }
}

@media screen and (max-width:550px){
    :root{
        --margin: 15px;
    }
    body{
        font-size:18px;
    }
    .main-two-up .portrait-gallery-block p{
        font-size:18px;
    }
}

.bev-banner {
    width: 100%; 
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .8) 75%, rgba(255, 255, 255, 0.98) 100%), url(https://res.cloudinary.com/beverly-willis-architecture-foundation/image/upload/v1618329223/bev-obit-expanded_wepuhm.jpg) no-repeat;
    height: 75vh; 
    min-height: 600px; 
    background-size: cover; 
    text-align: center; 
    display: flex;
    justify-content: end; 
    align-items: center;

    span {        
        color: #7427b0;
        display: flex; 
        font-size: 2.5rem;
        font-weight: bold;
        padding-right: 5em;
    }
}

@media screen and (max-width: 800px){
    .bev-banner {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .8) 75%, rgba(255, 255, 255, 0.98) 100%), url(https://res.cloudinary.com/beverly-willis-architecture-foundation/image/upload/v1618329223/bev_EDITED-crop_kz56m8.jpg) no-repeat;
        background-size: cover;
        height: calc(100svh - 230px);
        // max-height: 80dvh;
        // height: 100svh;
        justify-content: center; 
        align-items: end;
        span {
            font-size: 2rem;
            padding-right: 0;
            padding-bottom: 8rem;
            
        }
    }
}    

@media screen and (min-width: 801px) and (max-width: 1400px) {
    .bev-banner {
        
        span {
            font-size: 2rem;
            padding-right: 2em;
            padding-bottom: 2rem;
        }
    }
}

.tributes-block{
    display:flex;
    flex-wrap:wrap;
    > div{
        width:calc(33.333% - var(--gutter)*2/3);
        margin-right:var(--gutter);
    }
    > div:nth-child(3n+3){
        margin-right:0px;
    }
}
